.mosidebar {
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    display: none;
}


.addusers.deskaddusers {
    margin-right: 10px;
}

.addusers.deskaddusers:hover {
    opacity: 0.6;
}

.dark .addusers.deskaddusers:hover {
    opacity: 0.75;
}

.addusers {
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    text-decoration: none;
    color: #000;
    z-index: 2;
}

.addusers svg {
    height: 20px;
    width: 20px;
    fill: #000;
}

.dark .addusers svg {
    fill: #fff;
}

.addusers>small {
    font-size: 10px;
    font-weight: 700;
}



.mosrchinput {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 15px;
}

.mosidebarchats {
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
}

.sarrbackContainer.sarrback {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 0;
    cursor: pointer;
}

.deskSidebarBackButton {
    display: flex;
    width: 100%;
    justify-content: center;
    justify-content: left;
    margin-bottom: 20px;
}

.deskSidebarBackButton a,
.deskSidebarBackButton a span {
    display: flex;
    color: #000;
    align-items: center;
    justify-content: center;
}

.dark .deskSidebarBackButton a,
.dark .deskSidebarBackButton a span {
    color: #fff;
}

.deskSidebarBackButton svg {
    fill: #000;
}

.dark .deskSidebarBackButton svg {
    fill: #fff;
}

.deskSidebarBackButton span {
    font-size: 13px;
}

.deskSidebarBackButton svg {
    height: 22px;
    width: 22px;
    margin-right: 3px;
}


.deskSidebarBackButton .smappbcklnk svg {
    height: 14px;
    width: auto;
    margin-right: 3px;
}

.sarrbackContainer.sarrback h3 {
    font-size: 30px;
}

.sarrback {
    width: 90%;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
    cursor: pointer;
}

.sarrback>h3 {
    width: 80%;
    font-size: 25px;
}


.added {
    border: none;
    font-size: 11px;
    outline-width: 0;
    background-color: rgb(203, 203, 203);
    text-transform: lowercase;
    padding: 5px 15px;
    color: #000;
    border-radius: 10px;
}

.add {
    width: 50px;
    font-size: 11px;
    background-color: rgb(62, 117, 233000);
    color: white;
    border: none;
    text-transform: lowercase;
    padding: 5px 15px;
    border-radius: 10px;
    cursor: pointer;
}

.msrboddd {
    display: none;
}





@media only screen and (max-width: 800px) {
    .msrboddd {
        display: block;
    }

    .mosidebar {
        display: flex;
        position: relative;
    }

    .mobchatbod {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        overflow: hidden;
    }

    .dark .sidebars {
        border-bottom: 1px solid #ffffff3a;
    }
}

.arrback.arrbackmobChat {
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrback.arrbackmobChat~div img {
    flex-shrink: 0;
}

.desktchatbodhd .sideBarMailNotice {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 20px;
    border-radius: 10px;
    font-size: 10px;
}

.arrback svg {
    fill: #000;
}

.arrback.arrbackmobChat {
    cursor: pointer;
}

.dark .arrback svg {
    fill: #fff;
}

.arrbackmobChat svg {
    fill: #000;
    stroke: #000;
    stroke-width: 4px;
    height: 25px;
    width: auto;
    margin-right: 2px;
}

.dark .arrbackmobChat svg {
    fill: #fff;
    stroke: #fff;
    stroke-width: 4px;
}