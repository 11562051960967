@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro:400,500,600,700|Source+Sans+Pro:400,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,500,700&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
}

body {
  background-color: #fff;
  color: #000;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: 'Public Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dark body {
  background-color: #000;
  color: #fff;
}

#root,
#root>div {
  width: 100%;
  height: 100%;
}

.desksidebarheader .arrowback:hover {
  opacity: 0.6;
}

.dark .desksidebarheader .arrowback:hover {
  opacity: 0.75;
}

.arrowback svg {
  height: 14px;
  width: auto;
  fill: #000;
}

.dark .arrowback svg {
  fill: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dark .prlw {
  background-color: #222429;
}

.prlw {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: #ffffff;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: all;
  transition: all 0.5s;
  z-index: 9999;
}

.prlw-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  margin-bottom: 60px;
  position: relative;
}

.prlw-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 5px 20px 5px;
}

.prlw-bottom span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000;
  width: 100%;
  padding: 0 0 3px 0;
  font-size: 11px;
  line-height: 1;
  opacity: 0.5;
  position: relative;
}

.dark .prlw-bottom span {
  color: #fff;
  opacity: 0.6;
}

.prlw-bottom svg {
  width: 80px;
  fill: #000;
}

.dark .prlw-bottom svg {
  fill: #fff;
}

.preloading .prlw {
  visibility: visible;
  opacity: 1;
  filter: blur(0);
  pointer-events: auto;
}

body.preloading {
  overflow: hidden;
  overflow-y: hidden;
}

@media (min-width:650px) {
  .site-footer [class^=col-] {
    margin-bottom: 30px;
  }
}

.prlw-loader {
  position: relative;
  width: 60px;
  height: 60px;
}

.prlw-loader span {
  border: 0;
  margin: 0;
  width: 40%;
  height: 40%;
  position: absolute;
  animation: prlw-spin 2s ease infinite
}

.prlw-loader :first-child {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 378 378"><path d="M169 20.1Q87.3 28.4 44.6 89.7c-14.3 20.6-27.6 57.2-32.1 88.8-2 14-3.1 43-1.7 43.9 1.8 1.1 3.4-.2 13.4-11.3A191 191 0 0 1 146 146.9a224 224 0 0 1 40.5 0l4 .8-6 .7q-47.1 6-92.3 42.4A194 194 0 0 0 27.7 294c-5.5 22-10.5 61.6-8 64.1q2.7 2.9 8.8-5.4A236 236 0 0 1 78 304.2a198 198 0 0 1 67-27.7c11.4-2 43.6-2.3 57.5-.5 8.2 1 26 5.1 44 10 3.8 1 11.5 3 17 4.6l17.3 4.6 14.7 3.9 15 4c4.1 1.1 11 3 15.3 4 18 5 31.4 8 34.6 8 6.3 0 6.1 2 6.1-56.4 0-45.3-.3-55.2-1.8-67.1-8.7-67.9-43.1-122-96-151.3A186 186 0 0 0 169 20" fill="%23e80413" fill-rule="evenodd"/></svg>');
  animation-delay: -1.5s
}

.prlw-loader :nth-child(2) {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 378 378"><path d="M197 12a300 300 0 0 0-61.4 14.6c-10 4-30.8 14.5-39.5 20.1A189 189 0 0 0 60.7 78c-12.3 14.1-16 20-24.2 36.5q-21 43.2-19.2 84.7A194 194 0 0 0 36 273c11.3 22.4 33.6 47 56.5 62.1a237 237 0 0 0 44 21.8 298 298 0 0 0 68.5 11.6c17 .7 18.7-1.2 8.8-9.7A185 185 0 0 1 146 230.1c-1.2-12.7-1.3-39 0-41 1-1.6 1-1 3 12.9a222 222 0 0 0 15.8 46.8 211 211 0 0 0 49 62.5c18.7 15.6 47 30.4 71 37.1 32.2 9 71.2 13.4 71.2 7.9 0-.5-5-4.8-11-9.7a190 190 0 0 1-63-88.4c-7.5-23-10.5-43.4-9.7-65.7.8-21 2.4-28.3 18.7-88 16.2-59.3 22.6-86 22.1-92.2 0-1-11.6-1.3-54.4-1.2-29.8.1-57.6.5-61.7 1" fill="%23524899" fill-rule="evenodd"/></svg>');
  animation-delay: -1s
}

.prlw-loader :nth-child(3) {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 378 378"><path d="m351.6 30.3-18 19c-17.6 19-30 28.4-50.2 38.6a158 158 0 0 1-74.4 18.8c-19.6.6-30.1-.5-53.8-5.9-11-2.4-63.2-16-89.7-23.4C52.3 73.8 22 66.2 18.3 65.5c-3.6-.6-4.2-.4-4.7 1.3-.4 1.1-.7 24-.7 50.9-.1 50.2.5 62.2 4.7 86.3a225 225 0 0 0 21 62.9c21.3 39.6 57.4 71.5 96.6 85a155 155 0 0 0 76.6 9.2c36-5.4 57-12.5 78.8-27 34-22.2 60-60.8 72-107 3.5-13.7 5.1-25 6.5-45.3 1.2-18.1.9-22.8-1.5-22.8-.7 0-7.5 6.5-15 14.5-19 20-29.1 28-49 38.6a202 202 0 0 1-64.1 20.8 351 351 0 0 1-48.3 2.5q-8.3-2.2 6-4a139 139 0 0 0 35.3-9 186 186 0 0 0 98.5-86A223 223 0 0 0 359.8 39c1-17.8.8-18-8.2-8.7" fill="%2309a2de" fill-rule="evenodd"/></svg>');
  animation-delay: -0.5s
}

.prlw-loader :last-child {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 378 378"><path d="M156.3 10.8c-8.5.4-8.4 2.3.4 9.9C170 32 176.4 38.7 184 48c23 28.7 36.8 62.7 41 101.1 1.5 14.1 1.4 39-.2 38.6-.8-.3-2-4.5-3-9.9-5.4-31.4-15-52.5-36.2-79.4a192 192 0 0 0-69.4-56 183 183 0 0 0-49-16.5c-18.5-3.8-43-6.9-47.9-6-6.5 1-5.4 3 6.4 12.1q72.6 55.6 72.7 146c.1 28.8-2.1 39-36 163.8-3 11.2-5.4 21.3-5.2 22.5.4 3.1 5 3.5 43.3 3.6 42.3 0 82.7-1.4 94-3.4 19-3.3 36-9.2 58-20 43.8-21.6 78.9-60.7 93-103.7s9-96.4-13-138.7c-17.8-34.2-51.7-62.5-92.3-77a229 229 0 0 0-84-14.3" fill="%23fcbc04" fill-rule="evenodd"/></svg>');
}

@keyframes prlw-spin {

  0%,
  100% {
    transform: translate(0)
  }

  25% {
    transform: translate(160%)
  }

  50% {
    transform: translate(160%, 160%)
  }

  75% {
    transform: translate(0, 160%)
  }
}